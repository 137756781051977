/* eslint-disable no-loop-func */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../component/header";
import { BsPlusLg, BsDashLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BiPencil } from "react-icons/bi";
import { Button } from "react-bootstrap";
import { BsFillTrash3Fill } from "react-icons/bs";
import "react-multi-carousel/lib/styles.css";
import { STORAGEKEY } from "../../constants";
import { PostSaleCreate, storeStockList } from "../../services/tableQRServices";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementQuantity,
  decrementQuantity,
  placeOrderSuccess,
  addSalesNo,
  deleteCartItem,
  setTotal,
  setIsGSTInclusive,
  setIsServiceCharge,
  setIsGST,
  setTaxValue,
  setCartData,
} from "../../redux/cartSlice";
import Recommendation from "./recommendation";
import _ from "lodash";
import { ConfirmationModal } from "./confirmationModal";
import { Spinner } from "react-bootstrap";
import ConfirmModal from "./ConfirmModal";
import useProfile from "../../hooks/useProfile";
import { setAvailablePoints } from "../../redux/authSlice";
import getUpsellingItems from "../../utils/getUpsellingitems";
import UpsellingModal from "../../component/Upselling/UpsellingModal";
import useBrandConfig from "../../hooks/useBrandConfig";
import CartItem from "./CartItem";
import { getProducts } from "../../services/supabase/product";
import { sendPosLog } from "../../services/supabase/posLog";
import useSessionTimeout from "../../hooks/useSessionTimeout";
import usePromotion from "../../hooks/usePromotion";
import { CustomizeModal } from "../category/CustomizeModal";
import {
  setFirstTimeOpenFreeItemByValueModal,
  setFirstTimeOpenFreeItemWithLimitModal,
} from "../../redux/appSlice";
import Loading from "../../component/Loading/Loading";
import checkSoldOut from "../../utils/checkSoldOut";
import { roundNumber } from "../../utils/numberUtils";

function Cart() {
  const { config } = useBrandConfig();
  const sessionTimeout = useSessionTimeout();
  const {
    handleConvertPromotionTotal,
    handleConvertPromotionCart,
    handleConvertPromotionCartItem,
    handleConvertPromotionModifierItem,
    handleGroupCart,
  } = usePromotion();
  const { state } = useLocation();
  const cartData = useSelector((state) => state.cart.cart);
  const total = useSelector((state) => state.cart.total);
  const salesNo = useSelector((state) => state.cart.sales_no);
  const { isGST, isGSTInclusive, isServiceCharge, taxValue, taxList } =
    useSelector((state) => state.cart);
  const { availablePoints } = useSelector((state) => state.auth);
  const dineOption = localStorage.getItem("selectiDineOption");
  const orderTime = localStorage.getItem("orderTime");
  const [serviceTaxPer, setServiceTaxPer] = useState(0);
  const [serviceTax, setServiceTax] = useState(0);
  let gstInclusive = false;
  const [isErrorShow, setIsErrorShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [GSTTaxValue, setGSTTaxValue] = useState(0);
  const [GSTInclusiveTaxValue, setGSTInclusiveTaxValue] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const { myProfile } = useProfile(true);
  const [showModalData, setShowModalData] = useState([]);
  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [showUpsellingModal, setShowUpsellingModal] = useState(false);
  const [upsellingItems, setUpsellingItems] = useState([]);
  const [supaProducts, setSupaProducts] = useState({});

  const formatPromotionCartData = handleGroupCart(
    handleConvertPromotionCart(cartData)
  );

  const cartItemsCount = formatPromotionCartData.reduce(
    (acc, item) => acc + item.qty,
    0
  );

  const dispatcher = useDispatch();

  const diningMode = localStorage.getItem(STORAGEKEY.DINING_MODE);

  const [isLoading, setIsLoading] = useState(false);
  const [showPaylaterConfirmModal, setShowPaylaterConfirmModal] =
    useState(false);
  const [confirmData, setConfirmData] = useState({});

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  useEffect(() => {
    if (isServiceCharge && dineOption !== "takeaway") {
      setServiceTaxPer(0.1);
    }
  }, [isServiceCharge, dineOption]);

  useEffect(() => {
    if (isGSTInclusive) {
      setGSTInclusiveTaxValue(
        ((total / (taxValue + 100)) * taxValue).toFixed(2)
      );
    }

    if (isGST) {
      setGSTTaxValue(
        ((total + total * serviceTaxPer) * (taxValue / 100)).toFixed(2)
      );
    }

    if (isServiceCharge) {
      setServiceTax((total * serviceTaxPer).toFixed(2));
    }
  }, [
    total,
    config,
    serviceTaxPer,
    isGSTInclusive,
    isGST,
    isServiceCharge,
    taxValue,
  ]);
  const navigate = useNavigate();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };
  const incrementCounter = (uuid, cartItem) => {
    if (cartItem?.item?.disc_type === "P") {
      dispatcher(
        setAvailablePoints(
          availablePoints - cartItem?.item?.reward_type_attributes?.points
        )
      );
    }

    const itemInCart = formatPromotionCartData.find(
      (item) => item.uuid === uuid
    );

    dispatcher(
      incrementQuantity(handleConvertPromotionModifierItem(itemInCart))
    );
  };

  const decrementCounter = (uuid, cartItem) => {
    if (cartItem?.item?.disc_type === "P") {
      dispatcher(
        setAvailablePoints(
          availablePoints + cartItem?.item?.reward_type_attributes?.points
        )
      );
    }

    const itemInCart = formatPromotionCartData.find(
      (item) => item.uuid === uuid
    );
    const firstUuid = itemInCart?.uuidList?.[0];
    const findOriginalItem = cartData.find((item) => item.uuid === firstUuid);

    dispatcher(
      decrementQuantity(
        handleConvertPromotionModifierItem(findOriginalItem, "minus")
      )
    );
  };
  const formatDate = (date) => {
    const result = date.toLocaleString("en-SG", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return result;
  };
  const placeOrder = (id) => {
    const item = formatPromotionCartData.find((item) => item.uuid === id);
    const customDetails = {
      uuid: item.uuid,
      item: item.item,
      addOnValues: item.addOnValues,
      qty: item.qty,
      remarks: item.remarks,
    };

    if (config?.usingCustomizePage) {
      navigate("/customize-dish", {
        state: customDetails,
      });
    } else {
      setShowModalData(customDetails);
      setShowCustomizeModal(true);
    }
  };

  const handleFormatCartItem = (data) => {
    const newData = { ...data };
    const newDataItem = { ...data.item };
    const newFormatDataItem = {};

    newDataItem.itemmaster_menutypedtls = undefined;
    newDataItem.itemmaster_menutype = undefined;
    newDataItem.itemmaster_menutype_grpdtls = undefined;
    newDataItem.is_emenu_disable = undefined;
    newDataItem.is_soldout = undefined;
    newDataItem.bal_qty = undefined;
    newDataItem.avl_qty = undefined;
    newDataItem.reset_flag = undefined;
    newDataItem.shot_name = undefined;
    newDataItem.start_time = undefined;
    newDataItem.sku_no = undefined;
    newDataItem.upc_no = undefined;
    newDataItem.base_uom = undefined;
    newDataItem.default_price = undefined;
    newDataItem.takeaway_price = undefined;
    newDataItem.item_type = undefined;
    newDataItem.item_type_desc = undefined;
    newDataItem.item_image = undefined;
    newDataItem.is_menu_item = undefined;
    newDataItem.brand_name = undefined;
    newDataItem.gross_wgt_kg = undefined;
    newDataItem.default_cost = undefined;
    newDataItem.markup_per = undefined;
    newDataItem.vendor_code = undefined;
    newDataItem.reorder_qty_level = undefined;
    newDataItem.is_active = undefined;
    newDataItem.is_auto_reorder = undefined;
    newDataItem.is_returnable = undefined;
    newDataItem.is_alcohol = undefined;
    newDataItem.is_tobacco_item = undefined;
    newDataItem.dine_in_price = undefined;
    newDataItem.max_retail_price = undefined;
    newDataItem.is_openprice = undefined;
    newDataItem.is_weightable = undefined;
    newDataItem.course_seq = undefined;
    newDataItem.is_exclude_promo = undefined;
    newDataItem.is_pay_with_giftcard = undefined;
    newDataItem.is_pay_with_points = undefined;
    newDataItem.is_manualdisc = undefined;
    newDataItem.order_qty = undefined;
    newDataItem.is_level_item = undefined;
    newDataItem.printer_details = undefined;
    newDataItem.selling_uom_dtls = undefined;
    newDataItem.is_level_item = undefined;
    newDataItem.level_menu_button_dtls = undefined;
    newDataItem.dummy_price = undefined;
    newDataItem.supabase_item_desc = undefined;
    newDataItem.supaProduct = undefined;
    newDataItem.tax_name = undefined;
    newDataItem.isFree = undefined;

    Object.keys(newDataItem).forEach((key) => {
      if (newDataItem[key] !== undefined) {
        newFormatDataItem[key] = newDataItem[key];
      }
    });

    return {
      ...newData,
      item: newFormatDataItem,
    };
  };

  const paymentpage = async (usingOnlineLink, type) => {
    setIsLoading(true);
    setShowModal(false);

    let salesDetails = [];
    let i = 1;
    const currentDate = moment().format("YYYY/MM/DD HH:mm:ss");

    handleGroupCart(handleConvertPromotionCart(cartData), true).forEach(
      (cartItem, index) => {
        const priceDetails =
          cartItem?.item.selling_uom_dtls?.[0].price_dtls?.[0];
        let price =
          dineOption === "dinein"
            ? priceDetails?.dine_in_price || 0
            : priceDetails?.takeaway_price || 0;

        let parent_sno = i;
        if (cartItem.addOnValues.length) {
          let qty = cartItem.qty;
          while (qty > 0) {
            let sno = i;
            let parent_sno = i;

            cartItem = handleFormatCartItem(cartItem);

            salesDetails.push({
              s_no: sno++,
              ds_no: 1,
              parent_sno,
              seat_no: 1,
              qty: 1,
              uom_cf: 1,
              disc_type: "N",
              disc_value: 0,
              disc_amt: 0,
              disc_name: cartItem?.item?.promo_name || "",
              pro_disc_amt: 0,
              unit_price: 0.0,
              price: 0.0,
              sub_total: 0.0,
              ...cartItem?.item,
              svc_amt: 0.0,
              is_apply_svc: 1,
              tax_amt: 0.0,
              tax_rate: cartItem?.item?.tax_value,
              tax_value: cartItem?.item?.tax_value,
              is_absorbtax: cartItem?.item?.is_absorbtax,
              take_away_item: dineOption === "dinein" ? "N" : "Y",
              order_seq: 1,
              order_seq_type: "New",
              order_datetime: currentDate,
              print_flag: "N",
              item_kds_ready_status: "N",
              item_kds_ready_datetime: currentDate,
              item_kds_serve_status: "N",
              item_kds_serve_datetime: currentDate,
              override_f: 0,
              is_addon_enable: "N",
              add_on_name: "",
              c_date: currentDate,
              m_date: currentDate,
              menu_type: "",
              modifier_name: "",
              ref_1: "",
              ref_2: "",
              ref_3: "",
              ref_4: "",
              new_table_no: null,
              select_item: false,
              combo_qty: 1,
              combo_price: price,
              combo_total: price,
              checked: false,
              remarks: cartItem.remarks,
              $$hashKey: "object:3888",
              m_userid: "WEBORDER",
              c_userid: "WEBORDER",
            });
            const addOnSales = cartItem.addOnValues.map((addOn, subIndex) => {
              return {
                s_no: sno++,
                ds_no: subIndex + 2,
                parent_sno,
                seat_no: 1,
                qty: addOn?.modifier_qty ?? 1,
                uom_cf: 1,
                disc_type: "N",
                disc_value: 0,
                disc_name: "None",
                disc_amt: 0,
                pro_disc_amt: 0,
                category_code: cartItem.item.category_code,
                item_no: addOn.item_no,
                item_name: addOn.citem_name,
                item_desc: addOn.citem_name,
                sku_no: addOn.sku_no,
                uom: addOn.uom || "",
                unit_price: addOn.price_dtls,
                price: addOn.price_dtls,
                sub_total:
                  addOn.price_dtls === 0
                    ? 0
                    : Number(
                        (addOn.price_dtls * addOn.modifier_qty).toFixed(6)
                      ),
                svc_amt: isServiceCharge
                  ? Number(
                      Number(
                        (addOn.price_dtls * addOn.modifier_qty * 0.1).toFixed(6)
                      )
                    )
                  : 0,
                is_apply_svc: 1,
                tax_amt: Number(
                  Number(
                    cartItem?.item?.is_absorbtax
                      ? ((addOn.price_dtls * addOn.modifier_qty) /
                          (cartItem?.item?.tax_value + 100)) *
                          cartItem?.item?.tax_value
                      : (addOn.price_dtls * addOn.modifier_qty +
                          addOn.price_dtls *
                            addOn.modifier_qty *
                            serviceTaxPer) *
                          (cartItem?.item?.tax_value / 100)
                  ).toFixed(6)
                ),
                tax_rate: cartItem?.item?.tax_value,
                tax_value: cartItem?.item?.tax_value,
                is_absorbtax: cartItem?.item?.is_absorbtax,
                take_away_item: dineOption === "dinein" ? "N" : "Y",
                order_seq: 1,
                order_seq_type: "New",
                order_datetime: currentDate,
                print_flag: "N",
                item_kds_ready_status: "N",
                item_kds_ready_datetime: currentDate,
                item_kds_serve_status: "N",
                item_kds_serve_datetime: currentDate,
                override_f: 0,
                is_addon_enable: "N",
                add_on_name: "",
                c_userid: "WEBORDER",
                c_date: currentDate,
                m_userid: "WEBORDER",
                m_date: currentDate,
                menu_type: "",
                modifier_name: addOn.modifier_name,
                ref_1: "",
                ref_2: "",
                ref_3: "",
                ref_4: "",
                new_table_no: null,
                select_item: false,
                combo_qty: 0,
                combo_price: 0,
                combo_total: 0,
                checked: false,
                remarks: "",
                $$hashKey: "object:3888",
              };
            });
            i = sno;
            salesDetails = [...salesDetails, ...addOnSales];
            qty--;
            parent_sno++;
          }
        } else {
          const discountReward =
            cartItem?.item?.disc_type === "P"
              ? price * (cartItem?.item?.disc_value / 100)
              : cartItem?.item?.disc_value;
          const formatPrice = cartItem?.item?.reward_id
            ? price - (discountReward || 0)
            : price;

          cartItem = handleFormatCartItem(cartItem);

          salesDetails.push({
            s_no: i++,
            ds_no: 1,
            parent_sno,
            seat_no: 1,
            qty: cartItem?.qty ?? 1,
            uom_cf: 1,
            disc_type: "N",
            disc_value: 0,
            disc_amt: 0,
            disc_name: "None",
            pro_disc_amt: 0,
            ...cartItem?.item,
            unit_price: price,
            price: price,
            sub_total:
              formatPrice === 0
                ? 0
                : Number((formatPrice * cartItem.qty).toFixed(6)),
            svc_amt: isServiceCharge
              ? Number(Number((formatPrice * cartItem.qty * 0.1).toFixed(6)))
              : 0,
            is_apply_svc: 1,
            tax_amt: Number(
              Number(
                cartItem?.item?.is_absorbtax
                  ? ((formatPrice * cartItem.qty) /
                      (cartItem?.item?.tax_value + 100)) *
                      cartItem?.item?.tax_value
                  : (formatPrice * cartItem.qty +
                      formatPrice * cartItem.qty * serviceTaxPer) *
                      (cartItem?.item?.tax_value / 100)
              ).toFixed(6)
            ),
            tax_rate: cartItem?.item?.tax_value,
            tax_value: cartItem?.item?.tax_value,
            is_absorbtax: cartItem?.item?.is_absorbtax,
            take_away_item: dineOption === "dinein" ? "N" : "Y",
            order_seq: 1,
            order_seq_type: "New",
            order_datetime: currentDate,
            print_flag: "N",
            item_kds_ready_status: "N",
            item_kds_ready_datetime: currentDate,
            item_kds_serve_status: "N",
            item_kds_serve_datetime: currentDate,
            override_f: 0,
            is_addon_enable: "N",
            add_on_name: "",
            c_date: currentDate,
            m_date: currentDate,
            menu_type: "",
            modifier_name: "",
            ref_1: "",
            ref_2: "",
            ref_3: "",
            ref_4: "",
            new_table_no: null,
            select_item: false,
            combo_qty: 0,
            combo_price: 0,
            combo_total: 0,
            checked: false,
            remarks: "",
            $$hashKey: "object:3888",
            uom: "PORTION",
            item_desc: cartItem?.item?.item_desc,
            m_userid: "WEBORDER",
            c_userid: "WEBORDER",
          });
        }
      }
    );

    let orderMode = "";

    switch (type) {
      case "counter":
        orderMode = "PayAtCounter";
        break;

      case "card":
        orderMode = "PayFirst";
        break;

      case "qlubQR":
      case "paylater":
        orderMode = "PayLater";
        break;

      default:
        break;
    }

    const masterSubTotal = roundNumber(
      salesDetails?.reduce((a, b) => (a += Number(b?.sub_total)), 0)
    );
    const masterTaxTotal = roundNumber(
      salesDetails?.reduce((a, b) => (a += Number(b?.tax_amt)), 0)
    );
    const masterServiceChargeTotal = roundNumber(
      salesDetails?.reduce((a, b) => (a += Number(b?.svc_amt) || 0), 0)
    );

    let totalNetAmt = masterSubTotal + masterServiceChargeTotal;

    const isActualGSTInclusive =
      taxList?.filter((record) => record?.isGSTInclusive)?.length > 0;

    if (!isActualGSTInclusive) {
      totalNetAmt += masterTaxTotal;
    }

    const posPayload = {
      qr_code: localStorage.getItem(STORAGEKEY.QR_CODE),
      order_mode: orderMode,
      qr_type: config?.qr_type,
      comp_code: "01",
      store_name: localStorage.getItem(STORAGEKEY.STORE_NAME),
      doc_date: currentDate, // example 2021/01/01 00:00:00
      service_type: config?.is_quickservice
        ? "Q"
        : dineOption === "dinein"
        ? "E"
        : "T",
      service_type_info: dineOption === "dinein" ? "Dine In" : "Take Away",
      order_status_id: "N",
      order_status_desc: "New",
      kitchen_status_id: config?.is_quickservice ? "C" : "P",
      kitchen_status_desc: "In Progress",
      sub_total: masterSubTotal,
      total_svc: masterServiceChargeTotal,
      total_tax: masterTaxTotal,
      net_amt: roundNumber(totalNetAmt),
      table_no: localStorage.getItem(STORAGEKEY.TABLE_NO),
      sales_service_dtls: isServiceCharge
        ? [
            {
              service_name: "SERVICE CHARGE",
              service_by: "P",
              service_value: 10,
              sales_amt: Number(Number(total).toFixed(2)),
              service_amt: Number(Number(serviceTax).toFixed(2)),
            },
          ]
        : [],
      sales_payment_dtls: [],
      sales_other_info: "",
      payment_register_name: config?.register_no,
      payment_shift_code: "SHIFT1",
      table_transfer: "N",
      c_date: currentDate,
      m_userid: "",
      m_date: currentDate,
      disc_type: "N",
      disc_value: 0,
      disc_amt: 0,
      total_disc: 0.0,
      round_adj_amt: "0.00",
      absorb_tax: isActualGSTInclusive ? "Y" : "N",
      register_name: config?.register_no,
      shift_code: "SHIFT1",
      absorb_tax_info: "",
      table_transfer_sno: "",
      sales_no: "",
      customer_code: myProfile?.id || "",
      cust_addr_s_no: "",
      customer_name:
        myProfile?.last_name && myProfile?.first_name
          ? `${myProfile?.last_name} ${myProfile?.first_name}`
          : "",
      card_no: myProfile?.member_card_no ?? "",
      dob: "",
      contact_no: myProfile?.mobile_no ?? "",
      email: myProfile?.email ?? "",
      address: "",
      postal_code: "",
      disc_name: "None",
      tips_amt: 0,
      total_tender_amt: 0.0,
      change_amt: 0,
      no_of_pax: 1,
      remarks: "",
      del_driver: "",
      ref_1: "",
      ref_2: "",
      ref_3: "",
      ref_4: "",
      ref_5: "",
      order_from: "TQR",
      c_userid: "",
      sales_dtls: salesDetails,
      //  Additional
      mode_of_order: dineOption === "dinein" ? "Dine In" : "Take Away",
      kitchen_remarks: "",
      delivery_datetime: "",
      pickup_datetime:
        dineOption !== "dinein"
          ? orderTime === "now"
            ? formatDate(new Date())
            : formatDate(new Date(orderTime))
          : "",
    };

    if (config?.is_quickservice) {
      posPayload.kds_status = "C";
    }

    const body = [];
    body.push(posPayload);

    console.log("body", body);

    try {
      const res = await PostSaleCreate(body);
      const result = res?.data?.data[0];

      await sendPosLog(result?.sales_no, body?.[0]);
      setIsLoading(false);

      if (result.result === "SUCCESS") {
        dispatcher(addSalesNo(result?.sales_no));
        dispatcher(
          placeOrderSuccess({
            serviceTax,
            GSTTaxValue,
            order_status: "complete",
          })
        );
        setShowModal(false);
        setDisableButton(false);

        if (usingOnlineLink) {
          const storeName = localStorage.getItem(STORAGEKEY.STORE_NAME);
          const tableNo = localStorage.getItem(STORAGEKEY.TABLE_NO);

          navigate("/payment-callback", {
            state: {
              redirectLink: `${process.env.REACT_APP_QLUB_PAYMENT}/?order_id=${result?.sales_no}&restaurant_name=${storeName}&table_id=${tableNo}`,
            },
          });
        }
        return {
          success: true,
          message: result?.information,
        };
      } else {
        setIsErrorShow(true);
        setShowModal(false);
        setDisableButton(false);
        setErrorMessage(result?.information);

        return {
          success: false,
          message: result?.information,
        };
      }
    } catch (err) {
      setIsLoading(false);
      setIsErrorShow(true);
      setShowModal(false);
      setDisableButton(false);
      setErrorMessage(
        "We having trouble sending your order to the kitchen. Please click on submit to try again."
      );

      return {
        success: false,
        message:
          "We having trouble sending your order to the kitchen. Please click on submit to try again.",
      };
    }
  };
  const deleteCart = (uuid, cartItem) => {
    if (cartItem?.item?.disc_type === "P") {
      dispatcher(
        setAvailablePoints(
          availablePoints +
            cartItem?.item?.reward_type_attributes?.points * cartItem?.qty
        )
      );
    }

    if (cartItem?.isFree) {
      dispatcher(setFirstTimeOpenFreeItemByValueModal(false));
    }

    if (cartItem?.isFreeWithLimit) {
      dispatcher(setFirstTimeOpenFreeItemWithLimitModal(false));
    }

    const findUuidList =
      formatPromotionCartData?.find((record) => record?.uuid === uuid)
        ?.uuidList || [];

    findUuidList?.forEach((record) => {
      dispatcher(deleteCartItem(record));
    });
  };

  const storeName = localStorage.getItem(STORAGEKEY.STORE_NAME);

  const redirectHomepage = () => {
    navigate(`/home?storename=${storeName}`);
  };

  const handleCustomModalSuccessSubmit = async (submittedItem) => {
    const { hasUpsellingItems, items: upsellItems } = await getUpsellingItems(
      submittedItem.category_code
    );
    if (hasUpsellingItems) {
      setUpsellingItems(upsellItems);
      setShowUpsellingModal(true);
    }
  };

  const fetchSupabaseProducts = async () => {
    setIsLoading(true);
    const resp = await getProducts(process.env.REACT_APP_MODE);
    if (resp?.data?.length !== 0) {
      // look for items where is_top_pick is true
      const topPicks = resp?.data?.filter((item) => item.is_top_pick);
      const topPicksIds = topPicks?.map((item) => item.pos_item_no);
      const hashedProducts = resp?.data?.reduce((result, item) => {
        result[item.pos_item_no] = {
          name: item.name,
          dummyPrice: item.dummy_price,
          isTopPick: item.is_top_pick !== null ? true : false,
          image_url: item.image_url,
        };
        return result;
      }, {});
      setIsLoading(false);
      setSupaProducts(hashedProducts);
      return topPicksIds;
    }
  };

  useEffect(() => {
    fetchSupabaseProducts();
  }, []);

  const handleValidateStockBeforeSubmit = async () => {
    try {
      let disabled = false;

      const res = await storeStockList();

      const result = JSON.parse(res?.data?.data[0]?.output);
      let newCartData = [...cartData];

      newCartData = newCartData.map((data) => {
        const newData = {
          ...data,
        };
        const findDataFromStock = result?.find(
          (record) => record?.item_category === newData?.id
        );

        if (checkSoldOut(findDataFromStock)) {
          newData.is_main_soldout = true;
          disabled = true;
        } else {
          newData.is_main_soldout = false;
          disabled = false;
        }

        newData.addOnValues = newData?.addOnValues?.map((addOn) => {
          const newAddOn = {
            ...addOn,
          };
          const findAddOnDataFromStock = result?.find(
            (record) => record?.item_category === newAddOn?.item_no
          );

          if (checkSoldOut(findAddOnDataFromStock)) {
            disabled = true;
            newAddOn.is_addon_soldout = true;
          } else {
            disabled = false;
            newAddOn.is_addon_soldout = false;
          }

          return newAddOn;
        });

        return newData;
      });

      dispatcher(setCartData(newCartData));
      setSubmitButtonDisabled(disabled);

      return !disabled;
    } catch (error) {
      console.log(error);

      return false;
    }
  };

  const handleSubmit = async () => {
    setSubmitButtonDisabled(true);

    const result = await handleValidateStockBeforeSubmit();

    if (!result) {
      return;
    }

    setShowPaylaterConfirmModal(true);
  };

  const handlePaylaterConfirm = async () => {
    setShowPaylaterConfirmModal(false);

    if (config?.order_mode === "PayFirst") {
      setShowModal(true);
    } else {
      setSubmitButtonDisabled(true);

      const resp = await paymentpage(false, "paylater");
      if (resp.success) {
        navigate("/payment-options", {
          state: {
            pm: "paylater",
          },
        });
      }
      setSubmitButtonDisabled(false);
    }
  };

  const newTotal = useMemo(() => {
    let value = Number(total);

    if (isServiceCharge) {
      value += Number(serviceTax);
    }

    if (!taxList?.filter((record) => record?.isGSTInclusive)?.length) {
      value += taxList
        ?.filter((record) => !record?.isGSTInclusive)
        ?.reduce(
          (a, b) =>
            (a += b?.isGSTInclusive
              ? (total / (b?.taxAmt + 100)) * b?.taxAmt
              : (total + total * serviceTaxPer) * (b?.taxAmt / 100)),
          0
        );
    }
    return value;
  }, [total, isServiceCharge, taxList, serviceTax, serviceTaxPer]);

  useEffect(() => {
    if (formatPromotionCartData?.length > 0) {
      dispatcher(setTotal(formatPromotionCartData));
    }
  }, [formatPromotionCartData]);

  useEffect(() => {
    if (!cartData?.length) {
      dispatcher(setFirstTimeOpenFreeItemByValueModal(false));
    }
  }, [cartData]);

  return (
    <>
      {isLoading && (
        <Loading className="align-self-center position-absolute d-flex justify-content-center align-items-center h-100 w-100" />
      )}
      <div className="App">
        <div className="qr-before-body w-100">
          <Header title="Cart">
            <div className="w-100 bg-white pt-3">
              <div
                className="d-flex ps-4 pb-2 pe-3"
                style={{
                  position: "relative",
                  zIndex: 100,
                }}>
                <label className="recommendation-text">
                  RECOMMENDATIONS FOR YOU
                </label>
              </div>
              <div className="pb-3 ps-2 pe-2">
                <Recommendation
                  responsive={responsive}
                  supaProducts={supaProducts}
                />
              </div>
            </div>
          </Header>
          <div
            className="inner-div bottom-0 main-div w-100"
            style={{ paddingTop: 280, overflowY: "auto", height: "100%" }}>
            {formatPromotionCartData && formatPromotionCartData.length !== 0 ? (
              <>
                {/* <div
                  className="d-flex bg-white justify-content-between pt-2 ps-4 pb-2 pe-3"
                  style={{ marginTop: "300px" }}
                >
                  <div className="d-grid">
                    <label className="dine-option-text mb-1 text-uppercase">
                      {localStorage.getItem("selectiDineOption") === "takeaway"
                        ? "Takeaway"
                        : "Dine In"}
                    </label>
                    <label className="table-text mb-1 text-uppercase">
                      Table-{localStorage.getItem(STORAGEKEY.TABLE_NO)}
                    </label>
                  </div>
                  <div className="option-icon">
                    <span className="material-icons mt-3"> restaurant </span>
                  </div>
                </div> */}
                <div className="d-flex justify-content-between ps-4 pb-2 pe-3 pt-3">
                  <label className="order-summary-text">ORDER SUMMARY</label>
                </div>
                {formatPromotionCartData &&
                  formatPromotionCartData.length > 0 &&
                  formatPromotionCartData.map((cartItem, index) => (
                    <Fragment>
                      <CartItem
                        cartItem={handleConvertPromotionCartItem(cartItem)}
                        setConfirmData={setConfirmData}
                        deleteCart={deleteCart}
                        placeOrder={placeOrder}
                        decrementCounter={decrementCounter}
                        incrementCounter={incrementCounter}
                        supaProducts={supaProducts}
                      />
                    </Fragment>
                  ))}

                <div className="d-flex  ps-4 pb-2 pe-3 pt-3">
                  <label className="payment-summart-text">
                    PAYMENT SUMMARY
                  </label>
                </div>
                <div className="bg-white d-flex justify-content-between p-4 text-start">
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td className="sub-total-text pb-2">SUBTOTAL</td>
                        <td className="sub-total-text pb-2 text-end">
                          ${Number(total).toFixed(2)}
                        </td>
                      </tr>
                      {serviceTax > 0 && isServiceCharge && (
                        <tr>
                          <td className="sub-total-text pb-2">
                            SERVICE CHARGE (10%)
                          </td>
                          <td className="sub-total-text pb-2 text-end">
                            ${Number(serviceTax).toFixed(2)}
                          </td>
                        </tr>
                      )}
                      {taxList?.map((record) => (
                        <tr>
                          <td className="sub-total-text pb-2">
                            {record?.taxName}
                          </td>
                          <td className="sub-total-text pb-2 text-end">
                            $
                            {Number(
                              record?.isGSTInclusive
                                ? (total / (record?.taxAmt + 100)) *
                                    record?.taxAmt
                                : (total + total * serviceTaxPer) *
                                    (record?.taxAmt / 100)
                            ).toFixed(2)}
                          </td>
                        </tr>
                      ))}

                      <tr>
                        <td colSpan={2}>
                          <hr className="dashed-border" />
                        </td>
                      </tr>
                      {/* <tr>
                        <td className="public-sans-font fw-bold font-16 black-text line-height-21 pb-2 pt-2">
                          TOTAL
                        </td>
                        <td className="public-sans-font fw-bold font-16 black-text line-height-21 pb-2 pt-2 text-end">
                          $
                          {(
                            Number(total) +
                            Number(serviceTax) +
                            Number(GSTTaxValue)
                          ).toFixed(2)}
                        </td>
                      </tr> */}
                      {/* <tr>
                        <td
                          colSpan={2}
                          className="public-sans-font fw-normal font-16 black-text line-height-17 pt-2"
                        >
                          If you continue, you accept our{" "}
                          <Link
                            to="/profile"
                            className="privact-txt text-decoration-underline"
                          >
                            Privacy Policy
                          </Link>{" "}
                          and our{" "}
                          <Link
                            to="/profile"
                            className="privact-txt text-decoration-underline"
                          >
                            {" "}
                            Terms of Services
                          </Link>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>

                <div style={{ flex: 1 }}></div>
                <div className="position-sticky set-width bottom-0 bg-white cart-button-div pt-1 pb-1 ps-3 pe-3">
                  {isErrorShow && (
                    <p className="error-text text-danger text-start">
                      {errorMessage}
                    </p>
                  )}

                  {/* <Button
                    className="fill-green-btn cart-btn mt-2 public-sans-font fw-bold font-16 line-height-21 letter-spacing-02"
                    type="submit"
                    onClick={() => setShowModal(true)}
                  >
                    Place Order
                  </Button> */}

                  <button
                    disabled={
                      submitButtonDisabled ||
                      formatPromotionCartData?.length === 0
                    }
                    onClick={handleSubmit}
                    className="w-100 fill-btn sticky-btn position-sticky mb-2 mt-1 me-4">
                    <div className="d-flex align-items-center justify-content-between w-100 text-white">
                      <span className="place-order-text">Submit Order</span>
                      <span className="place-order-text">
                        items: {cartItemsCount}
                      </span>

                      <span className="place-order-text rounded-circle me-2 px-2 py-1">
                        $
                        {handleConvertPromotionTotal(
                          Number(newTotal),
                          Number(total)
                        ).toFixed(2)}
                      </span>
                    </div>
                  </button>

                  <ConfirmationModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    setDisableButton={setDisableButton}
                    disableButton={disableButton}
                    paymentpage={paymentpage}
                  />
                </div>
              </>
            ) : (
              <div style={{ height: "60vh" }}>
                <img
                  src={`/assets/commonImages/${config?.EmptyImage}.png`}
                  alt="empty Screen"
                  className="mt-5 pt-4"
                />
                <div className="d-grid mt-3 me-3 ms-3">
                  <label className="empty-cart-text mb-1 text-uppercase">
                    Your Cart is Empty
                  </label>
                  {/* <label className="public-sans-font fw-bold font-16 black-text line-height-20 ms-3 me-3 text-uppercase">
                    Looks like you haven't made your choice yet
                  </label> */}
                </div>
                <Button
                  className="fill-btn menu-btn w-50 mt-4"
                  type="submit"
                  onClick={redirectHomepage}>
                  Explore our Menu
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmModal
        open={confirmData?.open}
        title={confirmData?.title}
        description={confirmData?.description}
        onConfirm={confirmData?.onConfirm}
        onCancel={() => setConfirmData({})}
      />
      {showCustomizeModal ? (
        <CustomizeModal
          showModal={showCustomizeModal}
          setShowModal={setShowCustomizeModal}
          showModalData={showModalData}
          onSuccessSubmit={handleCustomModalSuccessSubmit}
        />
      ) : null}
      {showUpsellingModal ? (
        <UpsellingModal
          show={showUpsellingModal}
          handleClose={() => setShowUpsellingModal(false)}
          recommendationItems={upsellingItems}
        />
      ) : null}
      {showPaylaterConfirmModal && (
        <ConfirmModal
          open={showPaylaterConfirmModal}
          title={"Confirm Order"}
          description={"Please note that orders submitted cannot be cancelled"}
          onConfirm={handlePaylaterConfirm}
          onCancel={() => setShowPaylaterConfirmModal(false)}
        />
      )}
    </>
  );
}
export default Cart;
